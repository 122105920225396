@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* body {
        @apply h-screen font-display text-violet-50 antialiased accent-violet-300 selection:bg-violet-300 selection:text-violet-900 md:text-[18px] lg:text-[20px] xl:text-[24px] bg-[#141a2a];
    } */

    body {
        @apply h-screen font-display text-violet-50 antialiased accent-violet-300 selection:bg-violet-300 selection:text-violet-900 md:text-[18px] lg:text-[20px] xl:text-[24px];
        background-image: linear-gradient(
            90deg,
            rgb(16 4 16) 0%,
            rgb(0 32 48) 70%
        );
    }
}

@layer components {
    .root {
        @apply h-screen overflow-x-hidden;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }

    .card {
        @apply rounded-3xl p-5 shadow backdrop-blur;
    }

    .card-header-title-text {
        @apply whitespace-nowrap text-[0.9375em] font-medium uppercase md:tracking-wider;
    }

    .card-header-item-text {
        @apply text-[0.75em];
    }

    /*BUTTONS*/
    .btn {
        @apply /*relative is needed for the glass effect.*/ relative rounded-xl border-0 font-medium
        uppercase shadow-md ring-1 focus:outline-none focus:ring-4 disabled:opacity-75 sm:tracking-wide;
    }

    .btn.active {
        @apply ring-4;
    }

    .btn-rose {
        @apply bg-gradient-to-r from-pink-600 to-rose-600 ring-2 ring-rose-300/80 hover:from-pink-700 hover:to-rose-700 focus:ring-rose-500/50 active:from-pink-800 active:to-rose-800 disabled:bg-rose-600;
    }

    .btn-blue {
        @apply bg-blue-700 ring-blue-500/60 hover:bg-blue-600 focus:ring-blue-700/50 active:bg-blue-700 disabled:bg-blue-800;
    }

    .btn-indigo {
        @apply bg-indigo-700 ring-2 ring-indigo-500/60 hover:bg-indigo-600 focus:ring-indigo-700/50 active:bg-indigo-700 disabled:bg-indigo-800;
    }

    .btn-sky {
        @apply bg-sky-700 ring-2 ring-sky-500/60 hover:bg-sky-600 focus:ring-sky-700/50 active:bg-sky-700 disabled:bg-sky-800;
    }

    .btn-pink {
        @apply bg-pink-700 ring-2 ring-pink-500/60 hover:bg-pink-600 focus:ring-pink-700/50 active:bg-pink-700 disabled:bg-pink-800;
    }

    .btn-emerald {
        @apply bg-emerald-700 ring-2 ring-emerald-500/60 hover:bg-emerald-600 focus:ring-emerald-700/50 active:bg-emerald-700 disabled:bg-emerald-800;
    }

    .btn-violet {
        @apply bg-violet-700 ring-2 ring-violet-500/60 hover:bg-violet-600 focus:bg-violet-700/50 focus:text-violet-200 focus:ring-violet-700/50 active:bg-violet-700 disabled:bg-violet-800;
    }

    .btn-violet.active {
        @apply bg-violet-600 text-violet-100;
    }

    p {
        @apply text-justify;
    }

    .bg-diagonal-lines {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.05' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .bg-charlie-brown {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%239C92AC' fill-opacity='0.05'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

    .bg-diagonal-stripes {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.02' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    }
}
